<template>
  <div class="container mx-auto max-w-2xl py-24">
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div v-if="!submissionSuccess" class="space-y-6" key="feedback-form">
        <div class="text-lg font-semibold">
          We would love to know what you think...
        </div>
        <div class="text-gray-600">
          Please let us know your thoughts or suggestions on how we could
          improve.
        </div>
        <div class="text-gray-600">
          As a small business, your feedback is everything, thank you!
        </div>
        <div class="py-8">
          <star-selection v-model="stars" v-if="stars" />
        </div>
        <div>
          <textarea
            name="review-content"
            class="full-width"
            v-model="feedback"
            placeholder="Let us know what you think..."
          ></textarea>
        </div>

        <div class="flex sm:justify-center">
          <form
            @submit.prevent="sendFeedback"
            novalidate
            class="w-full sm:w-64"
          >
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              label="Submit Feedback"
              is-full
            />
          </form>
        </div>
      </div>
      <div v-else key="feedback-success" class="space-y-6">
        <div class="text-center text-2xl font-semibold">
          Thanks for your feedback.
        </div>
        <div class="text-center text-gray-600" v-if="stars >= 3">
          Thank you very much for this. We read everything thoroughly and it's
          really appreciated!
        </div>
        <div v-else class="text-center">
          We're really sorry you've had a bad experience.<br />
          This matters to us. We'll look into this and reply promptly.
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import StarSelection from '@/components/StarSelection.vue';
import LoadingButton from '@/components/LoadingButton.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Reviews',
  components: { StarSelection, LoadingButton },
  metaInfo: {
    meta: [
      {
        name: 'robots',
        content: 'noindex',
      },
    ],
  },
  data() {
    return {
      stars: 5,
      feedback: '',
      isLoading: false,
      submissionSuccess: false,
      emailAddress: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      isLoggedIn: 'isLoggedIn',
    }),
  },
  methods: {
    ...mapActions({
      sendToNotion: 'sendToNotion',
    }),
    sendFeedback() {
      this.isLoading = true;
      const data = {
        Name: {
          title: [
            {
              text: {
                content: this.currentUser ? this.currentUser.name : '-',
              },
            },
          ],
        },
        Score: {
          number: Number(this.stars),
        },
        Userid: {
          number: Number(this.currentUser ? this.currentUser.id : '-'),
        },
        Feedback: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.feedback,
              },
            },
          ],
        },
        Email: {
          rich_text: [
            {
              type: 'text',
              text: {
                content: this.currentUser.email,
              },
            },
          ],
        },
      };
      this.sendToNotion({
        db: 'a7453df0171a414e8c8cb50dee363e0f',
        data,
      })
        .then((response) => {
          this.isLoading = false;
          this.submissionSuccess = true;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.submissionSuccess = true;
        });
    },
  },
  mounted() {
    if (this.$route.query.score) {
      this.stars = Number(this.$route.query.score);
    }

    if (this.isLoggedIn) {
      this.emailAddress = this.currentUser.email;
    }
  },
};
</script>

<style></style>
