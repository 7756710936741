<template>
  <div class="flex items-center justify-center sm:space-x-3">
    <div class="hidden text-xs text-gray-500 sm:block">Could be better</div>
    <div class="flex items-center space-x-3" @mouseleave="resetColor()">
      <div
        class=""
        v-for="(star, index) in 5"
        :key="`star-${index}`"
        @click="setColor(index + 1)"
        @mouseover="setHoverColor(index)"
      >
        <div
          class="flex h-12 w-12 cursor-pointer items-center justify-center rounded bg-gray-100"
          :class="getColor(index)"
        >
          <font-awesome-icon
            class="text-2xl"
            fixed-width
            :icon="['fas', 'star']"
          />
        </div>
      </div>
    </div>
    <div class="hidden text-xs text-gray-500 sm:block">It's been great!</div>
  </div>
</template>

<script>
export default {
  name: "StarSelection",
  model: {
    prop: "stars",
    event: "input",
  },
  props: {
    stars: {
      type: Number,
    },
  },
  data() {
    return {
      currentFill: "yellow",
      currentIndex: null,
      hoverFill: null,
      hoverIndex: null,
      colors: ["red", "red", "yellow", "yellow", "yellow"],
    };
  },
  methods: {
    setHoverColor(index) {
      this.hoverFill = this.colors[index];
      this.hoverIndex = index;
    },
    setColor(index) {
      this.currentFill = this.colors[index - 1];

      this.$emit("input", index);
    },

    resetColor() {
      this.hoverFill = null;
      this.hoverIndex = null;
    },
    getColor(index) {
      if (this.hoverIndex || this.hoverIndex === 0) {
        if (index <= this.hoverIndex) {
          return `bg-${this.hoverFill}-300 text-${this.hoverFill}-500`;
        } else {
          return "bg-gray-300 text-gray-500";
        }
      } else {
        if (this.stars - 1 || this.stars - 1 === 0) {
          if (index <= this.stars - 1) {
            return `bg-${this.currentFill}-300 text-${this.currentFill}-500`;
          } else {
            return "bg-gray-300 text-gray-500";
          }
        }
      }
    },
  },
  watch: {
    stars(val) {
      this.currentFill = this.colors[val - 1];
    },
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.stars, this.colors);
      this.currentFill = this.colors[this.stars - 1];

      console.log("cc", this.currentFill);
    });
  },
};
</script>

<style></style>
